import React, { useEffect, useState } from 'react';
import { useCustomerService } from '@sus-core/state/xstate/customer/useCustomerService';

import { AddressView } from './AddressView';

import {
  AddressEditor,
  CustomerAddress,
} from '../../form/address/AddressEditor';
import { FormField } from '@sus-core/components/form/FormField';
import { Modal } from '@sus-core/components/modal/Modal';
import { Icons } from '@sus-core/components/icon/Icons';
import { Grid } from '@sus-core/components/layout/Grid';

import { useCustomerAddresses } from '../../../state/xstate/customer/useCustomerAddresses';
import { Button } from '@sus-core/components/button/Button';
import { navigate } from 'gatsby-link';
import { relaodCustomerData } from '@sus-core/state/xstate/customer/customer.machine';

export function AddressBook() {
  const {
    state,
    createCustomerAddress,
    updateCustomerAddress,
    deleteCustomerAddress,
  } = useCustomerService();

  const [
    addressToEdit,
    setAddressToEdit,
  ] = useState<Partial<CustomerAddress> | null>(null);

  const { defaultBilling, defaultShipping, other } = useCustomerAddresses();

  const handleDelete = address => {
    deleteCustomerAddress({ id: address.id });
  };

  useEffect(() => {
    relaodCustomerData();
  }, []);

  return (
    <section>
      <h2>Adressbuch</h2>
      <Button
        className="my-8"
        variant="bordered"
        size={2}
        onClick={() => navigate('/user/overview')}>
        zurück
      </Button>
      <Modal
        title={
          addressToEdit && Object.keys(addressToEdit).length > 0
            ? 'Adresse bearbeiten'
            : 'Neue Adresse anlegen'
        }
        isOpen={!!addressToEdit}>
        <AddressEditor
          address={{ ...addressToEdit }}
          onCancel={() => {
            setAddressToEdit(null);
          }}
          onSubmit={values => {
            delete values.__typename;
            delete values.extension_attributes;
            delete values.id;
            addressToEdit.id
              ? updateCustomerAddress({ id: addressToEdit.id, input: values })
              : createCustomerAddress(values);
            setAddressToEdit(null);
          }}>
          <FormField
            name="default_billing"
            label="Als Standard Rechnungsadresse verwenden"
            layout="row"
            labelClass="mr-auto"
            className="flex-row-reverse">
            <input type="checkbox" />
          </FormField>
          <FormField
            name="default_shipping"
            label="Als Standard Versandadresse verwenden"
            layout="row"
            labelClass="mr-auto"
            className="flex-row-reverse">
            <input type="checkbox" />
          </FormField>
        </AddressEditor>
      </Modal>

      <Grid className="gap-8">
        {defaultBilling && (
          <AddressView
            updating={state.context.updatingAddresses.includes(
              defaultBilling.id
            )}
            title={
              defaultBilling.default_shipping
                ? 'Rechnungs-/Lieferadresse'
                : 'Rechnungsadresse'
            }
            address={defaultBilling}
            onEditClick={setAddressToEdit}
          />
        )}
        {defaultShipping && !defaultShipping.default_billing && (
          <AddressView
            updating={state.context.updatingAddresses.includes(
              defaultShipping.id
            )}
            title="Versandadresse"
            address={defaultShipping}
            onEditClick={setAddressToEdit}
          />
        )}

        {other.map(address => (
          <AddressView
            deleting={state.context.deletingAddresses.includes(address.id)}
            updating={state.context.updatingAddresses.includes(address.id)}
            title="Adresse"
            key={address.id}
            address={address}
            onEditClick={() => setAddressToEdit(address)}
            onDeleteClick={handleDelete}
          />
        ))}

        <div className="b-box flex flex-col items-center">
          <h4 className="mb-auto">Neue Adresse anlegen</h4>
          <button
            onClick={() => setAddressToEdit({})}
            className="mt-4 mb-auto rounded-full border-default border-gray-4 p-6 flex flex-col justify-center items-center ">
            <Icons.Plus />
          </button>
        </div>
      </Grid>
    </section>
  );
}
