import moment from 'moment';
import React, { useEffect } from 'react';
import { navigate } from 'gatsby-link';
import { useLazyQuery } from '@apollo/client';

import { CUSTOMER_ORDERS_GET } from '@sus-core/api';
import { GetCustomerOrders } from 'src/generated/api.types';
import { Button } from '@sus-core/components/button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { useI18n } from '@sus-core/i18n/useI18n';
import { formatPrice } from '@sus-core/utils/price';

moment.locale('de');

export function CustomerOrders() {
  const { t } = useI18n();
  const [getOrders, { loading, data }] =
    useLazyQuery<GetCustomerOrders>(CUSTOMER_ORDERS_GET);

  useEffect(() => {
    getOrders();
  }, []);

  const items = data?.customerOrders?.items || [];

  return (
    <div>
      <Button
        variant="bordered"
        size={2}
        onClick={() => navigate('/user/overview')}>
        zurück
      </Button>
      <h2>MEINE BESTELLUNGEN</h2>
      <table className="w-full">
        <thead>
          <tr className="text-left">
            <th className="p-2">Bestelldatum</th>
            <th>Bestellnummer</th>
            <th>Gesamtpreis</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td className="text-center text-17 py-8" colSpan={4}>
                <FontAwesomeIcon icon={faSpinner} spin className="mr-4" />
                Bestellungen werden geladen...
              </td>
            </tr>
          )}

          {!loading && !items.length ? (
            <tr>
              <td className="text-center text-17 py-8" colSpan={4}>
                Sie haben noch keine Bestellungen.
              </td>
            </tr>
          ) : (
            data?.customerOrders?.items?.map(item => (
              <tr key={item.id} className="odd:bg-gray-5">
                <td className="p-2">{moment(item.created_at).format('LLL')}</td>
                <td>{item.order_number}</td>
                <td>{formatPrice(item.grand_total)}</td>
                <td>{t(item.status)}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
}
