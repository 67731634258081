import { ComponentType, ReactNode } from 'react';

import React from 'react';
import { navigate } from 'gatsby';

import { RouteComponentProps } from '@reach/router';
import { useAuthService } from '@sus-core/state/xstate/auth/useAuthService';
import { getRedirectParam } from '@sus-core/utils/getRedirectParam';

interface Props extends RouteComponentProps {
  component?: ComponentType<any>;
  render?: (props: any) => ReactNode;
  children?: ReactNode;
}

export const PrivateRoute = ({
  component: Component = React.Fragment,
  location,
  render,
  children,
  ...rest
}: Props) => {
  const { isAuthenticated } = useAuthService();

  if (!isAuthenticated && location && location.pathname !== '/anmelden') {
    navigate('/anmelden' + getRedirectParam(location.pathname));
    return null;
  }

  if (render) {
    return <>{render({ children, location, ...rest })}</>;
  }
  return <Component {...rest}>{children}</Component>;
};
