import React from 'react';
import Layout from '@sus-core/components/scaffolding/Layout';
import { Router, Redirect } from '@reach/router';

import { AccountOverview } from '@sus-core/components/user/account/Overview';
import { AccountEditor } from '@sus-core/components/user/account/AccountEditor';
import { AddressBook } from '@sus-core/components/user/account/AddressBook';
import { PrivateRoute } from '@sus-core/router/PrivateRoute';
import { CustomerOrders } from '@sus-core/components/user/orders/CustomerOrders';
import { PAGE_CONTAINER_CSS } from '@sus-core/utils/cssClasses';
import { PageProvider } from '@sus-core/hooks/page/PageContext';
import { SusPageProps } from '@sus-core/hooks/page/SusPageProps';

export default function UserProfilePage({
  location,
  pageContext,
}: SusPageProps) {
  return (
    <PageProvider
      data={{
        pageType: 'Customer Account',
        attributeMetaData: pageContext.attributeMetaData,
      }}>
      <Layout
        location={location}
        meta={{ title: 'Schrank und Stuhl - Benutzerkonto' }}>
        <div className={PAGE_CONTAINER_CSS}>
          <Router basepath="/user">
            <PrivateRoute path="overview" component={AccountOverview} />
            <PrivateRoute path="account" component={AccountEditor} />
            <PrivateRoute path="addressbook" component={AddressBook} />
            <PrivateRoute path="orders" component={CustomerOrders} />
            <Redirect from="/" to="overview" noThrow />
          </Router>
        </div>
      </Layout>
    </PageProvider>
  );
}
