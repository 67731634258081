import React, { useEffect } from 'react';
import { Form } from '@sus-core/components/form/Form';
import { Button } from '@sus-core/components/button/Button';
import {
  CountryCodeEnum,
  CreateCustomer_createCustomer_customer_addresses,
} from 'src/generated/api.types';
import { AddressEditorFields } from './AddressEditorFields';
import clsx from 'clsx';

export type CustomerAddress = Partial<
  Omit<
    CreateCustomer_createCustomer_customer_addresses,
    '__typename' | 'country_code' | 'extension_attributes'
  > & {
    country_code: string | CountryCodeEnum;
  }
>;

export function AddressEditor({
  address,
  onSubmit,
  onCancel,
  children,
  className,
}: {
  address?: Partial<CustomerAddress>;
  onSubmit?: (values: any) => void;
  onCancel?: () => void;
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <Form
      className={clsx(className, 'flex flex-col gap-4')}
      defaultValues={address ?? undefined}
      onSubmit={values => onSubmit?.({ ...values, id: address?.id })}
      render={formMethods => {
        useEffect(() => {
          formMethods.reset(address ?? undefined);
        }, [address]);

        return (
          <>
            <AddressEditorFields />

            {children}

            <div className="w-full flex justify-between">
              <Button size={2} variant="secondary" onClick={onCancel}>
                Abbrechen
              </Button>
              <Button
                size={2}
                variant="primary"
                disabled={
                  !formMethods.formState.isValid ||
                  !formMethods.formState.isDirty
                }
                type="submit"
                className="mr-1 mt-1">
                Speichern
              </Button>
            </div>
          </>
        );
      }}></Form>
  );
}
