import React from 'react';
import { FormField } from '@sus-core/components/form/FormField';
import { Form } from '@sus-core/components/form/Form';
import { useCustomerService } from '@sus-core/state/xstate/customer/useCustomerService';
import { Button } from '@sus-core/components/button/Button';

export function AccountEditor({
  onSubmitSuccess,
}: {
  onSubmitSuccess?: () => void;
}) {
  return (
    <div>
      <h2>Benutzerkonto bearbeiten</h2>

      <CustomerAccountForm onSubmitSuccess={onSubmitSuccess} />
    </div>
  );
}

export function CustomerAccountForm({
  onSubmitSuccess,
  onCancel,
}: {
  onSubmitSuccess?: () => void;
  onCancel?: () => void;
}) {
  const { customer, update, customerLoading } = useCustomerService();
  return (
    <Form
      onSubmit={(values, formState, { reset }) => {
        update({
          firstname: values.firstname,
          lastname: values.lastname,
          email: values.email,
          taxvat: values.taxvat,
        }).then(() => {
          reset();
          onSubmitSuccess?.();
        });
      }}
      render={({ formState }) => (
        <>
          <FormField name="firstname" label="Vorname">
            <input type="text" defaultValue={customer?.firstname} />
          </FormField>
          <FormField name="lastname" label="Nachname">
            <input type="text" defaultValue={customer?.lastname} />
          </FormField>
          <FormField name="email" label="E-Mail Adresse">
            <input type="email" defaultValue={customer?.email} />
          </FormField>
          <FormField name="taxvat" label="USt.-ID">
            <input type="text" defaultValue={customer?.taxvat} />
          </FormField>

          <div className="mt-8 flex justify-between">
            <Button onClick={onCancel} size={2} variant="secondary">
              Abbrechen
            </Button>
            <Button
              size={2}
              disabled={
                !formState.isDirty || !formState.isValid || customerLoading
              }
              variant="primary"
              loading={customerLoading}
              type="submit">
              Speichern
            </Button>
          </div>
        </>
      )}></Form>
  );
}
