import { useCustomerService } from '@sus-core/state/xstate/customer/useCustomerService';

export function useCustomerAddresses() {
  const {
    customer,
    createCustomerAddress,
    updateCustomerAddress,
    deleteCustomerAddress,
  } = useCustomerService();
  const addresses = customer?.addresses || [];

  const defaultShipping = addresses.find(address => address.default_shipping);
  const defaultBilling = addresses.find(address => address.default_billing);
  const other = addresses.filter(
    address => address != defaultBilling && address !== defaultShipping
  );

  return {
    defaultShipping,
    defaultBilling,
    other,
    create: createCustomerAddress,
    update: updateCustomerAddress,
    delete: deleteCustomerAddress,
  };
}
