import React, { ReactNode, useState } from 'react';
import { useCustomerService } from '@sus-core/state/xstate/customer/useCustomerService';
import { Button } from '@sus-core/components/button/Button';
import { Icons } from '@sus-core/components/icon/Icons';
import clsx from 'clsx';
import { useCustomerAddresses } from '@sus-core/state/xstate/customer/useCustomerAddresses';
import { useCountries } from '@sus-core/hooks/store/useCountries';
import { Modal } from '@sus-core/components/modal/Modal';
import { CustomerAccountForm } from './AccountEditor';
import { navigate } from 'gatsby-link';
import { GetCustomer_customer_addresses } from 'src/generated/api.types';

export function AccountOverview() {
  const { customer } = useCustomerService();

  const [editAccount, setEditAccount] = useState(false);

  const { defaultBilling, defaultShipping, other } = useCustomerAddresses();

  const addressToDisplay = defaultBilling || defaultShipping || other[0];

  return (
    <section>
      <h2>Ihre Übersicht</h2>
      <div className="flex flex-wrap gap-4">
        <Modal title="Benutzerkonto bearbeiten" isOpen={editAccount}>
          <CustomerAccountForm
            onCancel={() => setEditAccount(false)}
            onSubmitSuccess={() => setEditAccount(false)}
          />
        </Modal>
        <BorderedBox title="Benutzerkonto">
          <div className="overflow-ellipsis overflow-hidden">
            {customer?.firstname} {customer?.lastname}
          </div>
          <div className="overflow-ellipsis overflow-hidden">
            {customer?.email}
          </div>
          <div className="mb-auto overflow-ellipsis overflow-hidden">
            USt-IdNr.: {customer?.taxvat || '--'}
          </div>

          <Button
            size={1}
            variant="bordered"
            className="mt-6"
            onClick={() => setEditAccount(true)}>
            <Icons.Edit className="inline mr-4" />
            <span>Bearbeitern</span>
          </Button>
        </BorderedBox>

        <AddressBox address={addressToDisplay} />

        <BorderedBox title="Bestellungen">
          <div className="mb-auto">
            Hier finden Sie eine Übersicht aller Bestellungen mit aktuellem
            Status.
          </div>

          <Button
            size={1}
            variant="primary"
            className="mt-6"
            onClick={() => navigate('/user/orders')}>
            <span>Alle anzeigen</span>
          </Button>
        </BorderedBox>
      </div>
    </section>
  );
}

function AddressBox({ address }: { address?: GetCustomer_customer_addresses }) {
  const { resolveById } = useCountries();
  return (
    <BorderedBox title="Adressbuch">
      {address ? (
        <>
          <div className="overflow-ellipsis overflow-hidden">
            {address?.firstname} {address?.lastname}
          </div>
          <div className="overflow-x-hidden overflow-ellipsis">
            <span className="mr-2 overflow-ellipsis">{address?.street},</span>
            <span className="mr-2 overflow-ellipsis">{address?.postcode}</span>
            <span className="mr-2 overflow-ellipsis">{address?.city}</span>
            <span className="block overflow-ellipsis">
              {resolveById(address?.country_code)?.full_name_locale}
            </span>
          </div>
          <div className="mb-auto overflow-ellipsis overflow-hidden">
            {address?.telephone}
          </div>
        </>
      ) : (
        'Sie haben noch keine Adressen angelegt.'
      )}
      <Button
        size={1}
        variant="bordered"
        className="mt-auto"
        onClick={() => navigate('/user/addressbook')}>
        {address ? (
          <Icons.Edit className="inline mr-4" />
        ) : (
          <Icons.Plus className="inline mr-4" />
        )}
        <span>{address ? 'Bearbeiten' : 'Adresse anlegen'}</span>
      </Button>
    </BorderedBox>
  );
}

function BorderedBox({
  title,
  children,
  className,
}: {
  className?: string;
  title?: string;
  children?: ReactNode;
}) {
  return (
    <div
      className={clsx(
        'w-full md:flex-1 b-box flex flex-col gap-1 overflow-x-hidden',
        className
      )}>
      <h3>{title}</h3>
      {children}
    </div>
  );
}
